export const roleColumns = [
  { title: 'id', dataIndex: 'name', key: 'name' },
  { title: '用户名', dataIndex: 'NickName', key: 'nickName' },
  { title: '登录账号', dataIndex: 'Account', key: 'account' },
  { title: '密码', dataIndex: 'Password', key: 'password' },
];

export const roleAddRules = {
  NickName: [{ required: true, message: '请输入用户名', trigger: 'change' }],
  Account: [{ required: true, message: '请输入登录账号', trigger: 'change' }],
  Password: [{ required: true, message: '请输入密码', trigger: 'change' }],
};
