interface menu {
  name: string;
  key: string;
  icon: string;
  path: string;
  childer: Array<menu>;
}

export const menuConfig: Array<menu> = [
  // {
  //   name: '组织',
  //   key: '1',
  //   icon: 'icon-yemianguanli',
  //   path: '/',
  //   childer: [
  //     { name: '角色管理', icon: '', key: '1-1', childer: [], path: '/team/role' },
  //     { name: '部门管理', icon: '', key: '1-2', childer: [], path: '/team/department' },
  //     { name: '菜单管理', icon: '', key: '1-3', childer: [], path: '/team/menu' },
  //   ],
  // },
  {
    name: '用户',
    path: '/',
    key: '2',
    icon: 'icon-yonghu-tianchong',
    childer: [{ name: '用户管理', icon: '', key: '2-1', childer: [], path: '/member/player' }],
  },
  {
    name: '陪玩',
    path: '/',
    key: '3',
    icon: 'icon-huiyuanguanli',
    childer: [
      { name: '游戏分类', icon: '', key: '3-3', childer: [], path: '/npc/category' },
      { name: '陪玩列表', icon: '', key: '3-2', childer: [], path: '/npc/list' },
      { name: '技能管理', icon: '', key: '3-1', childer: [], path: '/npc/skill' },
    ],
  },
  {
    name: '商家',
    path: '/',
    key: '4',
    icon: 'icon-shangpinguanli',
    childer: [{ name: '商家中心', icon: '', key: '4-1', childer: [], path: '/merchant/list' }],
  },
  {
    name: '营销',
    path: '/',
    key: '7',
    icon: 'icon-yingxiaoguanli',
    childer: [
      { name: '优惠券管理', icon: '', key: '7-1', childer: [], path: '/coupon/list' },
      { name: '用户领取列表', icon: '', key: '7-2', childer: [], path: '/coupon/user/list' },
      { name: '商家领取列表', icon: '', key: '7-3', childer: [], path: '/coupon/shop/list' },
    ],
  },
  {
    name: '财务',
    path: '/',
    key: '8',
    icon: 'icon-yue',
    childer: [
      { name: '交易记录', icon: '', key: '8-1', childer: [], path: '/finance/record' },
      { name: '提现申请', icon: '', key: '8-2', childer: [], path: '/finance/apply' },
    ],
  },
  {
    name: '订单',
    path: '/',
    key: '5',
    icon: 'icon-ico_jiaoyiguanli',
    childer: [
      { name: '陪玩订单', icon: '', key: '5-1', childer: [], path: '/order/list' },
      { name: '退款订单', icon: '', key: '5-3', childer: [], path: '/order/refundList' },
      { name: '优惠券订单', icon: '', key: '5-2', childer: [], path: '/coupon/order/list' },
    ],
  },
  // /coupon/list
  {
    name: '站点',
    path: '/',
    key: '6',
    icon: 'icon-shezhi',
    childer: [
      { name: 'pc设置', icon: '', key: '6-1', childer: [], path: '/site/pc' },
      { name: '路由设置', icon: '', key: '6-2', childer: [], path: '/site/router' },
      { name: '企业管理', icon: '', key: '6-3', childer: [], path: '/site/company' },
      { name: '角色列表', icon: '', key: '6-4', childer: [], path: '/site/role' },
      { name: '员工列表', icon: '', key: '6-5', childer: [], path: '/site/staff' },
    ],
  },
  {
    name: '组队',
    path: '/',
    key: '9',
    icon: 'icon-yonghu-tianchong',
    childer: [
      { name: '队伍列表', icon: '', key: '9-1', childer: [], path: '/team/list' },
      { name: '消息列表', icon: '', key: '9-2', childer: [], path: '/message/list' },
    ],
  },
];

export const tableColumns = [
  { title: '姓名', dataIndex: 'name', key: 'name' },
  { title: '年龄', dataIndex: 'age', key: 'age' },
  { title: '住址', dataIndex: 'address', key: 'address' },
];

export const tableSource = [
  {
    key: '1',
    name: '胡彦斌',
    age: 32,
    address: '西湖区湖底公园1号',
  },
  {
    key: '2',
    name: '胡彦祖',
    age: 42,
    address: '西湖区湖底公园1号',
  },
];
