import request from './axios';


// 添加用户
export const addAccount = (data: { Account: string; Id: number; NickName: string; Password: string }) => {
  return request({
    url: '/manage/user/add',
    method: 'post',
    data: data,
  });
};


// 添加组队
export const addTeam = (data: any) => {
  return request({
    url: '/team/info/init',
    method: 'post',
    data: data,
  });
};


// 组队列表
export const teamList = (data: any) => {
  return request({
    url: '/team/info/list',
    method: 'get',
    params: data,
  });
};


// 组队详情
export const teamDetail = (id: any) => {
  return request({
    url: `/team/info/${id}/detail`,
    method: 'get',
  });
};


// 更新基本信息
export const teamUpdate = (id: any, data: any) => {
  return request({
    url: `/team/info/${id}/update`,
    method: 'put',
    data: data
  });
};

// 组队列表更新
export const teamPitUpdate = (id: any, data: any) => {
  return request({
    url: `/team/pit/${id}/update`,
    method: 'put',
    data: data
  });
};





// 组队作废
export const teamInvalid = (id: any) => {
  return request({
    url: `/team/info/${id}/invalid`,
    method: 'PATCH',
  });
};


// 组队开始
export const teamStart = (id: any) => {
  return request({
    url: `/team/info/${id}/start`,
    method: 'PATCH',
  });
};

// 组队结束
export const teamEnd = (id: any) => {
  return request({
    url: `/team/info/${id}/end`,
    method: 'PATCH',
  });
};



// 组队列表更新房间ID
export const teamRoomId = (id: any, ChatRoomId: any) => {
  return request({
    url: `team/info/${id}/room`,
    method: 'put',
    data: { ChatRoomId }
  });
};
