
import { defineComponent } from 'vue';

import { tableColumns, tableSource } from '@/data/config';

export default defineComponent({
  setup() {
    return {
      formState: {
        user: '',
        password: '',
      },
      tableColumns,
      tableSource,
    };
  },
});
