
import { defineComponent, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';

import { roleColumns, roleAddRules } from '@/data/modules/team/table';
import { awaitWrap } from '@/util/index';
import { addAccount } from '@/api/team';

export default defineComponent({
  setup() {
    const searchForm = reactive({
      Account: '',
      NickName: '',
    });

    function haeldAdd() {
      showUserInfo.value = true;
    }

    const showUserInfo = ref(false);
    const userInfo = reactive({
      Account: '',
      Id: 0,
      NickName: '',
      Password: '',
    });
    const formRef = ref();
    const haeldSubmit = async (callback: () => void) => {
      const validateRet = await awaitWrap(formRef.value.validate());
      if (validateRet.code === 200) {
        const ret: any = await awaitWrap(addAccount(userInfo));
        callback();
        if (ret[1]) {
          message.success('添加成功');
          showUserInfo.value = false;
        }
        return;
      }
      callback();
    };
    return {
      searchForm,
      formRef,
      haeldAdd,
      haeldSubmit,
      roleAddRules,
      showUserInfo,
      userInfo,
      roleColumns,
      tableSource: [],
    };
  },
});
